<template>
    <div>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container>
            <v-row align="center">

                <v-col>
                    <v-select
                    @change="changeReportType"
                    v-model="modelReportType"
                    :items="reportType"
                    label="Report Type"
                    prepend-icon="mdi-file-search"
                    ></v-select>
                </v-col>

                <v-col>
                    <v-select
                    v-model="modelYear"
                    @change="selectYear"
                    prepend-icon="mdi-calendar"
                    :items="dropdownYearDate"
                    label="Year"
                    >
                      </v-select>
                </v-col>

                <v-col
                v-if="modelReportType == 'Monthly'"
                >
                    <v-select
                    v-model="modelMonth"
                    @change="getMonthID($event)"
                    prepend-icon="mdi-calendar"
                    :items="dropdownMonthDate"
                    label="Month"
                    >
                      </v-select>
                </v-col>

                <v-col>
                    <v-btn
                    :disabled="disableBtnGenerate"
                    class="primary"
                    @click="generateReport"
                    >
                        Generate Report
                        <v-icon
                        right
                        dark
                        big
                        >
                        mdi-table-arrow-up
                        </v-icon>
                    </v-btn>
                </v-col>

            </v-row>

        </v-container>

        <v-container>

            <v-btn
            v-if="adminCanUse || operationCanUse || qaqcCanUse"
            class="primary mb-5"
            @click="selectOptionUpload = true"
            >
                Upload File
                <v-icon
                right
                dark
                big
                >
                mdi-file-upload
                </v-icon>
            </v-btn>

            <!-- Mini Title (Table Title) -->
            <!-- tbody_CMSR.length != 0 -->
            <v-row class="ma-0">
                <v-col class="ma-0">
                    <p
                    v-if="tbody_CMSR.length != 0"
                    class="text-center text-subtitle-2 fontSubTitle-blue"
                    >
                    {{modelReportType.toUpperCase()}} REPORT
                    </p>
                </v-col>
            </v-row>

            <v-data-table
            :headers="thead_CMSR"
            :items="tbody_CMSR"
            :header-props="{ sortIcon: null }"
            class="elevation-1 tableCMSReporting headerDtSarawak"
            :items-per-page="10"
            no-select-on-click
            fixed-header
            :loading="this.loadingTable"
            :loading-text="this.loadingText"
            >
            
                <template v-slot:[`item.report`]="{ item }">
                    <v-icon
                    dense
                    color="primary"
                    v-html="item.report"
                    @click="openFileReport(item)"
                    >
                    
                    </v-icon>
                </template>
            
                <template v-slot:[`item.edit`]="{ item }">
                    <v-icon
                    dense
                    :disabled="userCanUse"
                    color="green"
                    v-html="item.edit"
                    @click="editReport(item)"
                    >
                    
                    </v-icon>
                </template>
            
                <template v-slot:[`item.delete`]="{ item }">
                    <v-icon
                    dense
                    :disabled="userCanUse"
                    color="red"
                    v-html="item.delete"
                    @click="deleteReport(item)"
                    >
                    
                    </v-icon>
                </template>

            </v-data-table>

            <v-file-input
            class="d-none"
            label="File input"
            id="fileEdit"
            ref="fileEdit"
            v-on:change="handleEditInputFile( $event )"
            ></v-file-input>

        </v-container>


        <!-- Popup Upload File -->
        <template>
        <v-row justify="center">
            <v-dialog
            v-model="selectOptionUpload"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                <span class="text-h5">Upload File</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    
                    <v-row>

                        <v-col>
                            <v-select
                            @change="selectTypeUpload($event)"
                            v-model="modelUploadType"
                            :items="uploadType"
                            label="Upload Type"
                            prepend-icon="mdi-file-upload-outline"
                            ></v-select>
                        </v-col>

                        <v-col>
                            <v-select
                            @change="selectSubTypeUpload()"
                            v-model="modelUploadTypeYear"
                            prepend-icon="mdi-calendar"
                            :items="dropdownYearDate"
                            label="Year"
                            >
                            </v-select>
                        </v-col>

                        <v-col v-if="modelUploadType == 'Monthly'">
                            <v-select
                            @change="selectSubTypeUpload()"
                            v-model="modelUploadTypeMonth"
                            prepend-icon="mdi-calendar"
                            :items="dropdownMonthDate"
                            label="Month"
                            >
                            </v-select>
                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col>

                            <v-file-input
                            :disabled="disabledInputUpload"
                            label="File input"
                            show-size
                            truncate-length="15"
                            id="file"
                            ref="file"
                            v-on:change="handleFileUploadCMSR( $event )"
                            ></v-file-input>

                        </v-col>

                    </v-row>

                </v-container>
                <!-- <small>*indicates required field</small> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="primary"
                    @click="closeSelectOptionUpload"
                    >
                        Close
                    </v-btn>
                    <v-btn
                    :disabled="disableBtnUploadFile"
                    color="primary pl-5"
                    @click="uploadFileSelection"
                    >
                        Upload
                        <v-icon class="ml-1">
                            mdi-upload
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        </template>


        <!-- Popups/Dialogs Success -->
        <div class="text-center">
            <v-dialog
            v-model="dialogSuccess"
            width="400"
            persistent
            >
            <v-card>
                <v-card-title class="text-h5 success white--text">
                Success <v-icon style="color:white;margin-left:15px;">mdi-check-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                {{textDialogSuccess}}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialogSuccess = false"
                >
                    YES
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>


        <!-- Popups/Dialogs Confirmation Edit -->
        <div class="text-center">
            <v-dialog
            v-model="dialogConfirmationEdit"
            width="400"
            persistent
            >
            <v-card>
                <v-card-title class="text-h5 primary white--text">
                Edit Report <v-icon style="color:white;margin-left:15px;">mdi-file-upload</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Are you sure want to edit this report?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialogConfirmationEdit = false"
                >
                    NO
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="YesEditIt"
                >
                    YES
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>


        <!-- Popups/Dialogs Confirmation Delete -->
        <div class="text-center">
            <v-dialog
            v-model="dialogConfirmationDelete"
            width="400"
            persistent
            >
            <v-card>
                <v-card-title class="text-h5 error white--text">
                Delete <v-icon style="color:white;margin-left:15px;">mdi-delete</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Are you sure want to delete?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialogConfirmationDelete = false"
                >
                    NO
                </v-btn>
                <v-btn
                    color="error"
                    text
                    @click="YesDeleteIt"
                >
                    YES
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <!-- Popups/Dialogs Error -->
        <div class="text-center">
            <v-dialog
            v-model="dialogError"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 error white--text">
                Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Something when wrong. Please try again.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    style="color:red !important;"
                    color="error"
                    text
                    @click="dialogError = false;"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
// import Papa from "papaparse";

export default {
    data: () => ({

        // General
        dialogError: false,
        dialogSuccess: false,
        textDialogSuccess: "",

        // Path
        pathModul: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
                // href: '/Mapvsadiewer',
            },
            {
                text: 'CMS Reporting - Reporting',
                disabled: true,
                href: '/CMSReporting/Reporting',
            },
        ],

        // Filtering
        reportType: [
            "Yearly",
            "Monthly",
        ],
        dropdownYearDate: [],
        dropdownMonthDate: [
            "All Month",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        listMonthDate: [
            { id:"all", text:"All Month" },
            { id:"01", text:"January" },
            { id:"02", text:"February" },
            { id:"03", text:"March" },
            { id:"04", text:"April" },
            { id:"05", text:"May" },
            { id:"06", text:"June" },
            { id:"07", text:"July" },
            { id:"08", text:"August" },
            { id:"09", text:"September" },
            { id:"10", text:"October" },
            { id:"11", text:"November" },
            { id:"12", text:"December" }
        ],
        disableBtnGenerate: true,

        // Model
        modelReportType: null,
        modelYear: null,
        modelMonth: null,
        modelMonthID: null,

        // DataTable
        loadingTable: false,
        loadingText: "Data Loading... Please wait",
        thead_CMSR: [
            {text: "Report Name", value: "reportname", sortable: true},
            {text: "Upload Datetime", value: "uploaddatetime", sortable: true},
            {text: "Upload By", value: "uploadby", sortable: true},
            {text: "Update Datetime", value: "updatedatetime", sortable: true},
            // {text: "Update Report", value: "updateReport", sortable: false},
            {text: "Update By", value: "updateby", sortable: true},
            {text: "Report", value: "report", sortable: false},
            {text: "Edit", value: "edit", sortable: false},
            {text: "Delete", value: "delete", sortable: false},
        ],
        tbody_CMSR: [],
        dataForEdited: null,
        dialogConfirmationEdit: false,
        pathEditFile: "",
        dataForDeleted: null,
        dialogConfirmationDelete: false,

        // Upload File
        selectOptionUpload: false,
        uploadType: [
            "Yearly",
            "Monthly"
        ],
        modelUploadType: 'Yearly',
        modelUploadTypeYear: null,
        modelUploadTypeMonth: null,
        file: "",
        pathFile: "",
        disabledInputUpload: true,
        disableBtnUploadFile: true,

    }),
    methods: {

        // GET (General)

        load(){
            this.modelReportType = "Yearly"
        },

        getApiDateYear(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/years', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // this.stationDetails = response.data;
                // console.log(response.data);
                for (let i = 0; i < response.data.length; i++) {
                this.dropdownYearDate.push(response.data[i]);
                }

                
                // console.log(this.itemStation);

            })
            .catch(error => {
                console.log(error);
            })
        },

        selectYear(){

            if (this.modelReportType == "Yearly") {
                if (this.modelYear != null) {
                    this.disableBtnGenerate = false;
                }
                else{
                    this.disableBtnGenerate = true;
                }
            }
            else if(this.modelReportType == "Monthly"){
                if (this.modelMonth != null && this.modelYear != null) {
                    this.disableBtnGenerate = false;
                }
                else{
                    this.disableBtnGenerate = true;
                }
            }

            
        },

        getMonthID(month){

            for(let i in this.listMonthDate){
                if (this.listMonthDate[i].text == month) {
                    this.modelMonthID = this.listMonthDate[i].id;
                }
            }

            if (this.modelMonth != null && this.modelYear != null) {
                this.disableBtnGenerate = false;
            }
            else{
                this.disableBtnGenerate = true;
            }

        },

        changeReportType(){
            this.modelYear = null;
            this.modelMonth = null;
            this.modelMonthID = null;
            this.disableBtnGenerate = true;
        },

        // Generate Datatable

        generateReport(){

            this.loadingTable = true;

            this.tbody_CMSR = [];

            // let year = this.modelYear.toLowerCase();

            console.log(this.modelYear);
            console.log(this.modelMonthID);
            console.log(this.modelReportType);

            console.log('https://apis.spatialworks.com.my/sarawak/maint/docs?year='+this.modelYear+'&month='+this.modelMonthID+'&type='+this.modelReportType.toLowerCase());

            axios.get('https://apis.spatialworks.com.my/sarawak/maint/docs?year='+this.modelYear+'&month='+this.modelMonthID+'&type='+this.modelReportType.toLowerCase(), {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                let data = response.data;
                console.log(data);
                
                data.forEach(function(res){
                    res["report"] = 'mdi-file-document-outline';
                    res["edit"] = 'mdi-pencil';
                    res["delete"] = 'mdi-delete';
                });

                this.tbody_CMSR = data;
                this.loadingTable = false;

            })
            .catch(error => {
                console.log(error);
                this.loadingTable = false;
            })

        },

        openFileReport(data){
            console.log(data);
            window.open(data.filepath, '_blank');
        },

        editReport(data){
            console.log("edit file");

            this.dataForEdited = data;

            let fileUpload = document.getElementById('fileEdit');
            if (fileUpload != null) {
                fileUpload.click()
            }
        },

        handleEditInputFile(data){

            // console.log(data);

            let formData = new FormData();

            formData.append("files", data);

            axios.post(`https://apis.spatialworks.com.my/sarawak/cms/upload`, formData, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                },
            })
            .then((response) => {
                console.log(response);
                // this.stationData = response.data.filepath
                this.pathEditFile = response.data.filepath;
                
            })
            .catch((error) => {
                console.log(error);
            });

            let myInterval = setInterval(() => {
                if (this.pathEditFile != null) {
                    this.dialogConfirmationEdit = true;
                }
                else{
                    this.dialogError = true;
                }
                clearInterval(myInterval);
            }, 1000);

        },

        YesEditIt(){

            console.log(this.pathEditFile);

            axios.get('https://apis.spatialworks.com.my/sarawak/maint/docedit?id='+this.dataForEdited.id+'&fileurl='+this.pathEditFile, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                console.log(response.data);
                this.dialogConfirmationEdit = false;
                this.generateReport();
            })
            .catch(error => {
                console.log(error);
                this.dialogConfirmationEdit = false;
                this.dialogError = true;
            })

        },

        deleteReport(data){

            this.dialogConfirmationDelete = true;
            this.dataForDeleted = data;

        },

        YesDeleteIt(){

            axios.get('https://apis.spatialworks.com.my/sarawak/maint/docdelete?id='+this.dataForDeleted.id, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                console.log(response.data);
                this.generateReport();
                this.dialogConfirmationDelete = false;
            })
            .catch(error => {
                console.log(error);
                
                this.dialogConfirmationDelete = false;
                this.dialogError = true;

            })

        },

        // Upload File

        selectTypeUpload(type){

            this.modelUploadTypeYear = null;
            this.modelUploadTypeMonth = null;
            this.disabledInputUpload = true;
            this.disableBtnUploadFile = true;
            
        },

        selectSubTypeUpload(){

            if (this.modelUploadType == "Yearly") {
                if (this.modelUploadTypeYear != null) {
                    this.disabledInputUpload = false;
                    if (this.pathFile != "") {
                        this.disableBtnUploadFile = false;
                    }
                    else{
                        this.disableBtnUploadFile = true;
                    }

                }
                else{
                    this.disabledInputUpload = true;
                    this.disableBtnUploadFile = true;
                }
            }
            else if (this.modelUploadType == "Monthly"){
                if (this.modelUploadTypeYear != null) {

                    if (this.modelUploadTypeMonth != null) {
                        this.disabledInputUpload = false;
                        if (this.pathFile != "") {
                            this.disableBtnUploadFile = false;
                        }
                        else{
                            this.disableBtnUploadFile = true;
                        }
                    }
                    else{
                        this.disabledInputUpload = true;
                        this.disableBtnUploadFile = true;
                    }

                }
                else{
                    this.disabledInputUpload = true;
                    this.disableBtnUploadFile = true;
                }
            }

            

        },

        handleFileUploadCMSR(e){
            console.log(e);
            // console.log(this.$refs);
            // this.file = this.$refs.file.files[0];
            this.file = e;

            let formData = new FormData();

            formData.append("files", e);

            axios.post(`https://apis.spatialworks.com.my/sarawak/cms/upload`, formData, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                },
            })
            .then((response) => {
                console.log(response);
                // this.stationData = response.data.filepath
                this.pathFile = response.data.filepath;
                this.disableBtnUploadFile = false;
                
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response.status == 400) {
                    this.pathFile = "";
                    this.disableBtnUploadFile = true;
                }
            });

        },

        closeSelectOptionUpload(){

            this.selectOptionUpload = false;

            this.modelUploadType = "Yearly";
            this.modelUploadTypeYear = null;
            this.modelUploadTypeMonth = null;
            this.file = "";
            this.pathFile = "";
            this.$refs.file.reset();
            this.disabledInputUpload = true;
            this.disableBtnUploadFile = true;
        },

        uploadFileSelection(){

            let payload = {
                year: this.modelUploadTypeYear,
                month: this.modelUploadTypeMonth,
                fileurl: this.pathFile
            }

            for(let i in this.listMonthDate){
                if (this.listMonthDate[i].text == this.modelUploadTypeMonth) {
                    payload.month = this.listMonthDate[i].id;
                }
            }

            console.log(payload);
            

            // axios.post('https://apis.spatialworks.com.my/sarawak/maint/docadd', payload, {
            //     headers: {
            //         Authorization: "Bearer " + this.$store.getters.user.token,
            //     }
            // })
            // .then((response) => {
            //     console.log(response.data);
            //     this.selectOptionUpload = false;

            //     this.textDialogSuccess = "Your upload file is successful";
            //     this.dialogSuccess = true;
            // })
            // .catch((error) => {
            //     console.log(error);
            //     this.selectOptionUpload = false;
            //     this.dialogError = true;
            // });

        }

    },
    mounted(){
        this.load();
        this.getApiDateYear();
    }
}

</script>

<style lang="scss">
@import '~scss/main';

/* Section Path File/Modul */
.pathModul{
    padding-bottom: 0px;
}

.pathModul > li:nth-child(3) > a,
.pathModul > li:nth-child(3) > div{
    color: black !important;
}


</style>